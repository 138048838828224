import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function PreparationDetail({match}) {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [customer, setCustomer] = useState(undefined);
  const [vehicles, setvehicles] = useState(undefined);
  const headers = useFetchHeaders();
  const [redirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/customer/${match.params.customerId}`, {headers});
      const requestVehicles = await fetch(`${process.env.REACT_APP_API_HOST}/customer/vehicles/${match.params.customerId}`, {headers});

      const requestStatus = await request.status;
      const requestVehiclesStatus = await requestVehicles.status;

      if (requestStatus === 200 && requestVehiclesStatus === 200) {
        const data = await request.json();
        const vehicles = await requestVehicles.json();

        setCustomer(data);
        setvehicles(vehicles);
      } else {
        console.log("error", requestStatus);
      }
    }

    if (!customer) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title={customer?.company_name}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-n4">
              {customer?.street}, {customer?.zipcode} {customer?.city}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Ansprechpartner</span>
              {customer?.firstname}, {customer?.lastname}
            </div>
          </div>
          <div className="row">
            <div className="row mt-3">
              <span className="fw-bold d-block">E-Mail Adresse</span>
              <a href={`mailto:${customer?.email}`}>{customer?.email}</a>
            </div>
          </div>
          <div className="row">
            <div className="row mt-3">
              <span className="fw-bold d-block">Telefon</span>
              <a href={`tel:${customer?.phone}`}>{customer?.phone}</a>
            </div>
          </div>
        </div>


        <div className="container-fluid mt-5">
          <div className="row align-items-center bg-light rounded p-2">
            <div className="col-9 fw-bold">
              Zugewiesene Fahrzeuge
            </div>
            <div className="col-3 text-end">
              {/* <Link to="" className="btn btn-primary">Fahrzeug zuweisen</Link> */}
            </div>
          </div>
        </div>
        <div className="container-fluid mt-3">
          {vehicles && vehicles.map(vehicle => <React.Fragment key={vehicle.vehicle_id}>
          <div className="row align-items-center hover-light p-2">
            <div className="col-3">
              {vehicle.vin}
            </div>
            <div className="col-6">
              {vehicle.manufacturer} {vehicle.description}
            </div>
            <div className="col-3 text-end">
              {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(vehicle.mileage_from_odometer)} km
            </div>
          </div>
          </React.Fragment>)}
        </div>

      </Wrapper>
    </React.Fragment>
}