import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import useFetchHeaders from '../helper/fetchHeaders';
import Wrapper from '../components/Wrapper';

import 'bootstrap/js/dist/modal';

const UserDelete = ({match}) => {
  import('../helper/checkSession').then(check => check.default(true));
  
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState(false);

  useEffect(() => {
    if (!user) {
      fetchUser(match.params.userId);
    }
  });

  const fetchUser = async (userId) => {
    const request = await fetch(`${process.env.REACT_APP_API_HOST}/user/${userId}`, {headers});
    const {status} = await request;
    
    if (status === 200) {
      const response = await request.json();
      
      setUser(response);
    }
  }

  const deleteUser = async () => {
    const request = await fetch(`${process.env.REACT_APP_API_HOST}/user/${user.user_id}`, {
      headers,
      method: 'DELETE',
    });
    
    const {status} = await request;
    
    if (status === 200) {
      setRedirect('/users');
    }
  }
  
  return redirect ? 
    <Redirect to={redirect} /> : user ? <React.Fragment>
      <Wrapper title={`${user.firstname} ${user.lastname} löschen?`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-5 bg-white rounded text-center">
            <i className="fas fa-surprise text-danger fa-4x" />
            <div className="fw-bold text-danger h1 mt-3">Du bist dir sicher?</div>
            <p className="fw-bold text-start mt-5">Folgende Daten gehen unwiederruflich verloren</p>
            <ul className="text-start">
              <li>Benutzer (E-Mail, Vorname, Nachname, ...)</li>
            </ul>
            <button type="submit" className="btn btn-lg btn-danger fw-bold mt-5" onClick={() => deleteUser()}>{user.firstname} {user.lastname} jetzt löschen</button>
          </div>
        </div>
      </div>
      </Wrapper>
    </React.Fragment> : <>Loading</>;
}

export default UserDelete;