import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function Preparations() {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [preparations, setPreparations] = useState(undefined);
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/preparations`, {headers});

      const {status} = await request;

      if (status === 200) {
        const data = await request.json();
        setPreparations(data);
      } else {
        console.log("error", status);
      }
    }

    if (!preparations) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Aufbereitungen">
        <div className="container-fluid">
          <div className="row d-none d-lg-flex bg-light p-2 rounded">
            <div className="col-3 fw-bold">Name, Vorname</div>
            <div className="col-3 fw-bold">E-Mail</div>
            <div className="col-2 fw-bold">Paket</div>
            <div className="col-2 fw-bold">Preis</div>
            <div className="col-2 fw-bold">Angefragtes Datum</div>
          </div>
          {preparations && preparations.map(preparation => <React.Fragment key={preparation.preparation_id}>
            <div className="row hover-light p-2 cursor-pointer" onClick={() => setRedirect(`/preparation/detail/${preparation.preparation_id}`)}>
              <div className="col-12 col-lg-3 py-1 fw-bold">{preparation.lastname} {preparation.firstname}</div>
              <div className="col-12 col-lg-3 py-1">{preparation.email}<br/>{preparation.phone}</div>
              <div className="col-12 col-lg-2 py-1">{preparation.package_number}</div>
              <div className="col-12 col-lg-2 py-1 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(preparation.calculated_price)}</div>
              <div className="col-12 col-lg-2 py-1">
                {new Intl.DateTimeFormat('de-DE', {weekday: 'long', day: 'numeric', month: '2-digit', year: 'numeric'}).format(new Date(preparation.requested_date))}<br/>
                {new Intl.DateTimeFormat('de-DE', {hour: '2-digit', minute: '2-digit'}).format(new Date(preparation.requested_date))} Uhr
              </div>
            </div>
          </React.Fragment>)}
        </div>
      </Wrapper>
    </React.Fragment>
}