import React, {useContext} from 'react';

import StoreContext from '../../storeContext';

const ContactForm = () => {
  const {dispatch, state} = useContext(StoreContext);
  
  const handleSubmitContact = async (event) => {
    event.preventDefault();

    const valuateRequest = await fetch(`${process.env.REACT_APP_API_HOST}/widget/vehicle/valuate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        datECode: state.identify.datECode,
        email: state.formMail,
        firstname: state.formFirstname,
        lastname: state.formLastname,
        mileage: state.formMileage,
        phone: state.formPhone,
        registrationDate: state.formRegistrationDate,
        vin: state.formVin,
      }),
    });

    if (valuateRequest.status === 201) {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormIdentifyVehicleByVin: false,
        showFormIdentifyVehicleByDropDown: false,
        showFormContact: false,
        showFormVerifyCode: true,
      }});
    } 
    else if (valuateRequest.status === 409) {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormIdentifyVehicleByVin: false,
        showFormIdentifyVehicleByDropDown: false,
        showFormContact: false,
        showFormError409: true,
      }});
    }
    else {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormIdentifyVehicleByVin: false,
        showFormIdentifyVehicleByDropDown: false,
        showFormContact: false,
        showFormTechnicalError: true,
      }});
    }
  }

  return state.showFormContact ? <>
    <form onSubmit={handleSubmitContact}>
      <div className="container my-5 px-5">
        <div className="row mt-5">
          <div className="col-12 col-md-6">
            <input type="text" className="form-control" placeholder="Vorname" value={state.formFirstname} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formFirstname: e.target.value}})} required />
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <input type="text" className="form-control" placeholder="Nachname" value={state.formLastname} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formLastname: e.target.value}})} required />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <input type="email" className="form-control mt-3" placeholder="E-Mail Adresse" value={state.formMail} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formMail: e.target.value}})} required />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <input type="tel" className="form-control mt-3" placeholder="Telefon" value={state.formPhone} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formPhone: e.target.value}})} required />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-1 text-end">
            <input type="checkbox" className="form-check-input" required />
          </div>
          <div className="col-11">
            <p className="text-secondary">Mir ist bewusst, dass das finale Verkaufsangebot unter Berücksichtigung von Schäden und Fahrzeugzustand vor Ort erstellt wird</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1 text-end">
            <input type="checkbox" className="form-check-input" required />
          </div>
          <div className="col-11">
            <p className="text-secondary">Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="/datenschutz" target="_blank">Datenschutzerklärung</a></p>
          </div>
        </div>
        <div className="row">
          <div className="col-1 text-end">
            <input type="checkbox" className="form-check-input" required />
          </div>
          <div className="col-11">
            <p className="text-secondary">Die PS Kraftwagen GmbH darf mich nach der kostenlosen Schätzung meines KfZ per E-Mail oder Telefon kontaktieren um das Angebot zu besprechen.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <p><span className="fw-bold">Hinweis: </span>Sie erhalten eine E-Mail mit einem Bestätigungscode. Diesen geben Sie hier auf der nächsten Seiten ein und erhalten sofort eine kostenlose Bewertung!</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-end">
            <button type="submit" className="mt-4 btn btn-secondary btn-lg px-5 border-white" disabled={state.formFirstname && state.formLastname && state.formMail && state.formPhone ? '' : 'disabled'}>
              <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">E-Mail Bestätigung absenden</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </> : null
}

export default ContactForm;