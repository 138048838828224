import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

const Dasboard = () => {
  import('../helper/checkSession').then(check => check.default(true));

  const [evaluations, setEvaluations] = useState(null);
  const [preparations, setPreparations] = useState(null);
  const [users, setUsers] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [redirect] = useState(null);
  const headers = useFetchHeaders();

  useEffect(() => {
    const fetchData = async () => {
      const requestEvaluations = await fetch(`${process.env.REACT_APP_API_HOST}/evaluations`, {headers});
      const requestPreparations = await fetch(`${process.env.REACT_APP_API_HOST}/preparations`, {headers});
      const requestUsers = await fetch(`${process.env.REACT_APP_API_HOST}/users`, {headers});
      const requestCustomers = await fetch(`${process.env.REACT_APP_API_HOST}/customers`, {headers});

      const evaluationsResponse = await requestEvaluations;
      const preparationsResponse = await requestPreparations;
      const usersResponse = await requestUsers;
      const customersResponse = await requestCustomers;

      if (evaluationsResponse.status === 200 && preparationsResponse.status === 200 && usersResponse.status === 200 && customersResponse.status === 200) {
        const dataEvaluations = await requestEvaluations.json();
        const dataPreparations = await requestPreparations.json();
        const dataUsers = await requestUsers.json();
        const dataCustomers = await requestCustomers.json();

        setEvaluations(dataEvaluations);
        setPreparations(dataPreparations);
        setUsers(dataUsers);
        setCustomers(dataCustomers);
      } else {
        console.log("error", evaluationsResponse.status, preparationsResponse.status, usersResponse.status, customersResponse.status);
      }
    }

    if (!evaluations && !preparations) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Dashboard (Statistik)">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="bg-light rounded-3 p-3">
                <div className="container">
                  <div className="row">
                    <Link to="/customers" className="col-3 border-end text-center text-decoration-none">
                      <span className="fw-bold">Kunden</span>
                      <span className="d-block display-4">{customers && customers.length}</span>
                    </Link>
                    <Link to="/evaluations" className="col-3 border-end text-center text-decoration-none">
                      <span className="fw-bold">Bewertungen</span>
                      <span className="d-block display-4">{evaluations && evaluations.length}</span>
                    </Link>
                    <Link to="/preparations" className="col-3 border-end text-center text-decoration-none">
                      <span className="fw-bold">Aufbereitungen</span>
                      <span className="d-block display-4">{preparations && preparations.length}</span>
                    </Link>
                    <Link to="/users" className="col-3 text-center text-decoration-none">
                      <span className="fw-bold">Benutzer</span>
                      <span className="d-block display-4">{users && users.length}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-4">
              <div className="bg-white rounded shadow p-3">
                <p>
                  <span className="fw-bold">Fahrzeuge im Bestand</span>
                  <span className="d-block display-4">{vehicles.length}</span>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
}

export default Dasboard;