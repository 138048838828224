import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

const Users = () => {
  import('../helper/checkSession').then(check => check.default(true));
  
  const headers = useFetchHeaders();
  const [users, setUsers] = useState(undefined);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/users`, {headers});

      const {status} = await request;

      if (status === 200) {
        const data = await request.json();

        setUsers(data);
      }
    }

    if (!users) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Benutzer" navigationItems={[{name: 'Erstellen', to: '/user/add'}]}>

        <div className="container-fluid mb-5" style={{marginTop: '-70px'}}>
          <div className="row">
            <div className="col-12 text-end">
              <div className="btn-group me-2" role="group">
                <Link to='/user/add' type="button" className="btn btn-dark">Benutzer erstellen</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid d-none d-lg-block">
          <div className="row bg-light p-2 rounded">
            <div className="col-4 fw-bold">E-Mail</div>
            <div className="col-4 fw-bold">Name</div>
            <div className="col-2 fw-bold">Status</div>
            <div className="col-2 fw-bold">Erstellt am</div>
          </div>
          {users && users.map(user => <React.Fragment key={user.user_id}>
            <div className="row hover-light p-2 cursor-pointer" onClick={() => setRedirect(`/user/${user.user_id}`)}>
              <div className="col-4 py-1">{user.email}</div>
              <div className="col-4 py-1">{user.firstname} {user.lastname}</div>
              <div className="col-2 py-1">{user.status}</div>
              <div className="col-2 py-1">{new Date(user.created_at).toLocaleDateString(navigator.language, {year: 'numeric', month: '2-digit', day: '2-digit'})}</div>
            </div>
          </React.Fragment>)}
        </div>
      </Wrapper>
    </React.Fragment>
}

export default Users;