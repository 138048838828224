import {useContext} from 'react';

import StoreContext from '../storeContext';

const useFetchHeaders = () => {
  const {state} = useContext(StoreContext);
  
  const headers = {
    'Content-Type': 'application/json',
    'x-session-id': sessionStorage.getItem('sid'),
  }

  return state ? headers : {};
}

export default useFetchHeaders;