import React, {useContext} from 'react';

import StoreContext from '../../storeContext';

import 'bootstrap/js/dist/modal';

const IdentifyVehicleByVin = ({identifyVehicleByDropDownText}) => {
  const {dispatch, state} = useContext(StoreContext);
  
  const handleSubmitIdentify = async (event) => {
    event.preventDefault();

    dispatch({type: 'UPDATE_STORE', payload: {
      showFormIdentifyVehicleByVin: false,
      showFormContact: true,
    }});
  }

  const modalBoxHelpFin = () => <>
    <div className="modal fade" id="modal-help-fin" data-bs-keyboard="false" aria-labelledby="modal-help-fin-label" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen-lg-down modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="p fw-bold">Wo finde ich meine Fahrzeug-Identifizierungsnummer (FIN)?</div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            Jedes Auto hat eine individuelle Fahrzeug-Identifizierungsnummer welche das eigene Auto kennzeichnet. 
            Vor 1981 war diese noch als Fahrgestellnummer bekannt.<br/>
            Die Fahrzeug-Identifizierungsnummer hat 17 Stellen und steht in den Kfz-Papieren:<br/>
            <ul className="mt-3">
              <li>Fahrzeugschein (Zulassungsbescheinigung Teil I) <span className="fw-bold">Feld E</span>, oder</li>
              <li>Fahrzeugbrief (Zulassungsbescheinigung Teil II)</li>
            </ul>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>
  </>

  const modalBoxHelpRegistrationDate = () => <>
    <div className="modal fade" id="modal-help-registration-date" data-bs-keyboard="false" aria-labelledby="modal-help-registration-date-label" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen-lg-down modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="p fw-bold">Wo finde ich das Datum der Erstzulassung?</div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">

            Das Datum der Erstzulassung finden Sie in den Kfz-Papieren:<br/>
            <ul className="mt-3">
              <li>Fahrzeugschein (Zulassungsbescheinigung Teil I) <span className="fw-bold">Feld B</span>, oder</li>
              <li>Fahrzeugbrief (Zulassungsbescheinigung Teil II)</li>
            </ul>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>
  </>

  return state.showFormIdentifyVehicleByVin ? <>
    {modalBoxHelpFin()}
    {modalBoxHelpRegistrationDate()}
    <form onSubmit={handleSubmitIdentify}>
      <div className="container-fluid my-5 px-md-5">
        <div className="container">
          <div className='row justify-content-center'>
            <div className="col-12 text-end">
              <div className="text-secondary cursor-pointer" onClick={(e) => dispatch({type: 'UPDATE_STORE', payload: {showFormIdentifyVehicleByDropDown: true, showFormIdentifyVehicleByVin: false}})}>&raquo; Ich kenne meine <span className="fw-bold">Fahrzeug-Identifizierungsnummer (FIN) nicht</span></div>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-12">
              <span>Fahrzeug-Identifizierungsnummer (FIN) <i className="fas fa-info-circle ms-1 cursor-pointer" data-bs-toggle="modal" data-bs-target="#modal-help-fin" /></span>
              <input type="text" className="form-control form-control-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formVin: e.target.value}})} value={state.formVin} maxLength="17" pattern="[A-Za-z0-9]{17}" placeholder="17-stellige Fahrzeug-Identifizierungsnummer" required />
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-12 col-md-6">
              <span>Erstzulassung <i className="fas fa-info-circle ms-1 cursor-pointer" data-bs-toggle="modal" data-bs-target="#modal-help-registration-date" /></span>
              <input type="date" className="form-control form-control-lg" value={state.formRegistrationDate} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formRegistrationDate: e.target.value}})} disabled={!state.formVin ? 'disabled' : ''} max={new Date().toISOString().split('T').shift()} required />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <span>Kilometerstand (km)</span>
              <input type="tel" className="form-control form-control-lg" value={state.formMileage} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formMileage: e.target.value}})} disabled={!state.formMileage && !state.formVin ? 'disabled' : ''} required />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-end">
              <button type="submit" className="mt-4 btn btn-secondary btn-lg w-md-0 px-5 border-white" disabled={state.formRegistrationDate && state.formMileage ? '' : 'disabled'}>
                <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Weiter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </> : null
}

export default IdentifyVehicleByVin;