import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function Vehicles() {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [evaluations, setEvaluation] = useState(undefined);
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/evaluations`, {headers});

      const {status} = await request;

      if (status === 200) {
        const data = await request.json();
        setEvaluation(data);
      } else {
        console.log("error", status);
      }
    }

    if (!evaluations) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Bewertungen">
        <div className="container-fluid">
          <div className="row d-none d-lg-flex bg-light p-2 rounded">
            <div className="col-3 fw-bold">VIN / DAT €uropa-Code®</div>
            <div className="col-3 fw-bold">E-Mail</div>
            <div className="col-2 fw-bold">Bewertung</div>
            <div className="col-3 fw-bold">Erstellt</div>
          </div>
          {evaluations && evaluations.map(evaluation => <React.Fragment key={evaluation.evaluation_id}>
            <div className="row hover-light p-2 cursor-pointer" onClick={() => setRedirect(`/evaluation/detail/${evaluation.evaluation_id}`)}>
              <div className="col-12 col-lg-3 py-1 text-uppercase">{evaluation.datecode ? evaluation.datecode : evaluation.vin}</div>
              <div className="col-12 col-lg-3 py-1">{evaluation.is_verified ? <i className="fas fa-user-check text-success me-1"/> : <i className="fas fa-user text-muted me-1"/>} {evaluation.email}</div>
              <div className="col-12 col-lg-2 py-1 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(evaluation.purchase_price_min)}</div>
              <div className="col-12 col-lg-3 py-1">{new Intl.DateTimeFormat('de-DE', {day: 'numeric', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(evaluation.created_at))} Uhr</div>
            </div>
          </React.Fragment>)}
        </div>
      </Wrapper>
    </React.Fragment>
}