import React, {useContext, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import StoreContext from '../storeContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState(false);
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(null);
  const {dispatch} = useContext(StoreContext);

  const handleSubmit = async(event) => {
    event.preventDefault();

    try {
      const loginRequest = await fetch(`${process.env.REACT_APP_API_HOST}/user/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, password})
      });

      const {status} = await loginRequest;

      if (status === 200) {
        const {sid} = await loginRequest.json();
        
        sessionStorage.setItem('sid', sid);

        dispatch({type: 'UPDATE_STORE', payload: {
          sid,
        }});
        
        setRedirect('/dashboard');
      } else {
        setFormError(true);
      }
    } catch(e) {
      setFormError(true);
    }
  }

  const formErrorSection = () => formError ? <React.Fragment>
    <div className="container my-3">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 bg-white rounded p-3">
          <p className="m-0 fw-bold text-danger">Anmeldung fehlgeschlagen</p>
          <p className="m-0">Bitte erneut versuchen und die Zugangsdaten prüfen.</p>
        </div>
      </div>
    </div>
  </React.Fragment> : null;
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
    <div className="container-fluid bg-light vh-100">
      <header className="container">
        <div className="row">
          <div className="col-12 text-center">
            <i className="fas fa-user-tie fa-7x text-dark mt-5 cursor-pointer" />
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <h1 className="text-dark font-weight-light mt-3 text-center text-uppercase">kraft<span className="fw-bold">karre</span></h1>
          </div>
        </div>
      </div>
      {formErrorSection()}
      <form className="container" onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <input type="text" className="form-control mt-4" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} required autoFocus />
            <input type="password" className="form-control mt-3" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4 text-right">
            <button className="btn btn-dark w-100 mt-4" type="submit">Anmelden</button>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-6 col-lg-4">
            <Link to="/register" className="mt-2 d-block link-light">Registrieren</Link>
            <a href="/" className="mt-2 d-block link-light">Passwort vergessen?</a>
          </div>
        </div>
      </form>
    </div>
    </React.Fragment>
}

export default Login;