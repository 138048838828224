import React, {useContext, useEffect, useState} from 'react';

import AccessTokenInvalid from '../widgets/AccessTokenInvalid';
import ContactForm from '../widgets/Fahrzeugbewertung/ContactForm';
import Error409 from '../widgets/Fahrzeugbewertung/Error409';
import Error500 from '../widgets/Fahrzeugbewertung/Error500';
import IdentifyVehicleByVin from '../widgets/Fahrzeugbewertung/IdentifyVehicleByVin';
import IdentifyVehicleByDropDown from '../widgets/Fahrzeugbewertung/IdentifyVehicleByDropDown';
import ProgressStepper, {ProgressStep} from '../widgets/Fahrzeugbewertung/ProgressStepper';
import Success200 from '../widgets/Fahrzeugbewertung/Success200';
import StoreContext from '../storeContext';
import VerificationFailed from '../widgets/Fahrzeugbewertung/VerificationFailed';
import VerifyCode from '../widgets/Fahrzeugbewertung/VerifyCode';

import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/js/dist/modal';

const Fahrzeugbewertung = () => {
  const [accessTokenIsValid, setAccessTokenIsValid] = useState(null);
  const {dispatch, state} = useContext(StoreContext);
  const [progressStep1, setProgressStep1] = useState(null);
  const [progressStep2, setProgressStep2] = useState(null);
  const [progressStep3, setProgressStep3] = useState(null);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const {apiKey} = Object.fromEntries(urlSearchParams.entries());

  
  
  useEffect(() => {
    const fetchCheckAccessToken = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/user/login/${apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-id': sessionStorage.getItem('sid'),
        }
      });

      const {status} = await request;

      if (status === 200) {
        setAccessTokenIsValid(true);
      } else {
        setAccessTokenIsValid(false);
      }
    }

    if (accessTokenIsValid === null) {
      fetchCheckAccessToken();
    }

    if (state.showFormIdentifyVehicleByDropDown || state.showFormIdentifyVehicleByVin) {
      setProgressStep1({isActive: true, isDone: false, onClickCallback: () => {}});
      setProgressStep2({isActive: false, isDone: false, onClickCallback: () => {}});
      setProgressStep3({isActive: false, isDone: false, onClickCallback: () => {}});
    }

    else if (state.showFormContact) {
      setProgressStep1({isActive: false, isDone: true, onClickCallback: () => {dispatch({type: 'UPDATE_STORE', payload: {showFormContact: false, showFormIdentifyVehicleByVin: true}})}});
      setProgressStep2({isActive: true, isDone: false});
    }

    else if (state.showFormVerifyCode) {
      setProgressStep1({isActive: false, isDone: true, onClickCallback: () => {}});
      setProgressStep2({isActive: false, isDone: true, onClickCallback: () => {}});
      setProgressStep3({isActive: true, isDone: false});
    }

    else if (state.showFormSuccess) {
      setProgressStep1({isActive: false, isDone: true, onClickCallback: () => {}});
      setProgressStep2({isActive: false, isDone: true, onClickCallback: () => {}});
      setProgressStep3({isActive: false, isDone: true});
    }
  }, [
    accessTokenIsValid,
    apiKey,
    dispatch,
    state.showFormIdentifyVehicleByDropDown, 
    state.showFormIdentifyVehicleByVin, 
    state.showFormContact,
    state.showFormVerifyCode,
    state.showFormSuccess,
    state.selectedManufacturer,
    state.selectedBaseModel, 
    state.selectedSubModel, 
    state.selectedCarBodyOption, 
    state.selectedEngineOption, 
    state.selectedGearingOption
  ]);

  const renderWidgetFahrzeugbewertung = () => <>
    <ProgressStepper>
      <ProgressStep id="1" description={state.selectedManufacturer && state.identify.manufacturers.find(manufacturer => manufacturer.key === state.selectedManufacturer).value} isActive={progressStep1 && progressStep1.isActive} isDone={progressStep1 && progressStep1.isDone} onClickCallback={progressStep1 && progressStep1.onClickCallback}>1. Auswahl Fahrzeug</ProgressStep>
      <ProgressStep id="2" description="" isActive={progressStep2 && progressStep2.isActive} isDone={progressStep2 && progressStep2.isDone} onClickCallback={progressStep2 && progressStep2.onClickCallback}>2. Kontaktdaten</ProgressStep>
      <ProgressStep id="3" description="" isActive={progressStep3 && progressStep3.isActive} isDone={progressStep3 && progressStep3.isDone} onClickCallback={progressStep3 && progressStep3.onClickCallback}>3. Bewertung per E-Mail</ProgressStep>
    </ProgressStepper>

    <IdentifyVehicleByVin />
    <IdentifyVehicleByDropDown />
    <ContactForm />
    <VerificationFailed />
    <VerifyCode />
    <Success200 />
    <Error409 />
    <Error500 />
  </>

  return <>
    {!state.identify.isServiceAvailable && <>SERVICE NOT AVAILABLE</>}
    
    {!accessTokenIsValid && <AccessTokenInvalid />}

    {state.identify.isServiceAvailable && accessTokenIsValid && renderWidgetFahrzeugbewertung()}
  </>
}

export default Fahrzeugbewertung;