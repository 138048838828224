import React from 'react';

const initialState = {
  formFirstname: '',
  formLastname: '',
  formMail: '',
  formPhone: '',
  formVerifyCode: '',
  formVin: '',
  formMileage: '',
  formRegistrationDate: '',
  identify: {
    isServiceAvailable: true,
  },
  selectedManufacturer: undefined,
  selectedBaseModel: undefined,
  selectedSubModel: undefined,
  selectedGearingOption: undefined,
  selectedCarBodyOption: undefined,
  selectedEngineOption: undefined,
  showFormTechnicalError: false,
  showFormIdentifyVehicleByDropDown: false,
  showFormIdentifyVehicleByVin: true,
  showFormErrorIdentifyVehicle: false,
  showFormContact: false,
  showFormVerifyCode: false,
  showFormSuccess: false,
  showFormError409: false,
  showFormErrorVerificationFailed: false,
};

function reducer(store, action) {
  switch (action.type) {
    case 'UPDATE_STORE':
      return {...store, ...action.payload};
    default:
      throw new Error();
  }
}

const StoreContext = React.createContext();

export { StoreContext as default, initialState, reducer };