import React from "react";

const ProgressStepper = ({children}) => <>
  <div className="container-fluid sticky-top bg-light border-bottom py-4">
    <div className="container">
      <div className="row">
        {children && children.map(step => 
          <div key={step.props.id} className="col-4 text-center cursor-pointer" onClick={step.props.onClickCallback}>
            {!step.props.isActive && !step.props.isDone ? <span className="p fw-normal">{step.props.children}</span> : null}
            {step.props.isActive ? <span className="p fw-bold text-success">{step.props.children}</span> : null}
            {step.props.isDone ? <span className="p fw-bold text-success">{step.props.children}</span> : null}
          </div>
        )}
      </div>
      <div className="row justify-content-center align-items-center mt-3">
        {children && children.map((step, index) => <React.Fragment key={step.props.id}>
          <div className="col-2 col-lg-2 text-center cursor-pointer" onClick={step.props.onClickCallback}>
            {!step.props.isActive && !step.props.isDone ? <i className="far fa-circle fa-2x text-primary" /> : null}
            {step.props.isActive ? <i className="fas fa-circle fa-2x text-success" /> : null}
            {step.props.isDone ? <i className="fas fa-check-circle fa-2x text-success" /> : null}
          </div>
          {(index+1) < children.length ? <div className="col-3 col-lg-2 px-0">
              {!step.props.isActive && !step.props.isDone ? <hr /> : null}
              {step.props.isActive || step.props.isDone ? <hr className="fw-bolder p-1 rounded" /> : null}
            </div> 
          : null}
        </React.Fragment>
        )}
      </div>
      <div className="row">
        {children && children.map(step => 
          <div key={step.props.id} className="col-4 text-start text-lg-center">
            {step.props.description ? <span className="p fw-bold text-success d-block mt-1">{step.props.description}</span> : null}
          </div>
        )}
      </div>
    </div>
  </div>
</>

const ProgressStep = () => <></>;

export {ProgressStepper as default, ProgressStep};