import React, {useContext} from 'react';

import StoreContext from '../../storeContext';

const VerifyCode = () => {
  const {dispatch, state} = useContext(StoreContext);

  const handleSubmitVerifyCode = async (event) => {
    event.preventDefault();

    const valuateVerifyRequest = await fetch(`${process.env.REACT_APP_API_HOST}/widget/vehicle/valuate/verify/${state.formVerifyCode}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (valuateVerifyRequest.status === 201) {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormVerifyCode: false,
        showFormErrorVerificationFailed: false,
        showFormSuccess: true,
      }});
    } else {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormErrorVerificationFailed: true,
      }});
    }
  }

  return state.showFormVerifyCode && <>
    <form onSubmit={handleSubmitVerifyCode}>
      <div className="container my-5 px-5">
        <div className="row mt-5">
          <div className="col-12">
            <p>Bitte geben Sie Ihren Verifizierungscode, den Sie per E-Mail erhalten haben, jetzt ein.</p>
            <input type="text" className="form-control form-control-lg" placeholder="Verifizierungscode" value={state.formVerifyCode} onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formVerifyCode: e.target.value}})} required autoFocus />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-end">
            <button type="submit" className="mt-4 btn btn-secondary btn-lg px-5 border-white" disabled={state.formFirstname && state.formLastname && state.formMail && state.formPhone ? '' : 'disabled'}>
              <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Verifizieren</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </>
}

export default VerifyCode;