import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function PreparationDetail({match}) {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [preparation, setPreparation] = useState(undefined);
  const headers = useFetchHeaders();
  const [redirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/preparation/${match.params.preparationId}`, {headers});

      const {status} = await request;

      if (status === 200) {
        const data = await request.json();
        setPreparation(data);
      } else {
        console.log("error", status);
      }
    }

    if (!preparation) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Aufbereitung">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="row bg-light rounded">
                <div className="col-12 p-3">
                  <span className="fw-bold">Kontaktdaten</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Name, Vorname</span>
                  {preparation?.firstname}, {preparation?.firstname}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">E-Mail Adresse</span>
                  <a href={`mailto:${preparation?.email}`}>{preparation?.email}</a>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Telefon</span>
                  <a href={`tel:${preparation?.phone}`}>{preparation?.phone}</a>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Angefragt am</span>
                  {preparation ? new Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}).format(new Date(preparation.created_at)) : null} Uhr
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Herkunft</span>
                  {preparation?.origin}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Betreff</span>
                  {preparation?.subject}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row bg-light rounded">
                <div className="col-12 p-3">
                  <span className="fw-bold">Angefragtes Paket</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Paketnummer</span>
                  {preparation?.package_number}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Angefragtes Datum</span>
                  {preparation ? new Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}).format(new Date(preparation.requested_date)) : null} Uhr
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Preis</span>
                  {preparation ? new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR' }).format(preparation.calculated_price) : null}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Fahrzeugklasse</span>
                  {preparation?.vehicle_class === 'car' ? <>Auto</> : null}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Ausgewähltes Paket</span>
                  {preparation?.selected_package_title}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Paketkategorien</span>
                  {preparation?.selected_package_categories.length > 0 ? preparation?.selected_package_categories.map(category => <React.Fragment key={category.id}>
                    <span className="d-block">{category.title}</span>
                  </React.Fragment>) : <span>Keine Kategorien gebucht</span>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="fw-bold d-block">Paketextras</span>
                  {preparation?.selected_package_extras.length > 0 ? preparation?.selected_package_extras.map(extra => <React.Fragment key={extra.id}>
                    <span className="d-block">{extra.title}</span>
                  </React.Fragment>) : <span>Keine Extras gebucht</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
}