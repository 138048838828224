import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function Vehicles() {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [vehicles, setVehicles] = useState(undefined);
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/vehicles`, {headers});

      const {status} = await request;

      if (status === 200) {
        const data = await request.json();
        setVehicles(data);
      } else {
        console.log("error", status);
      }
    }

    if (!vehicles) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Fahrzeuge">
        <div className="container-fluid mt-n5">
          <div className="row">
            <div className="col-12 text-end mt-n3">
              <Link to="/vehicle/add" className="btn btn-primary">Neues Fahrzeug</Link>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row bg-light p-2 rounded">
            <div className="col-6 col-md-3 fw-bold">VIN</div>
            <div className="col-6 fw-bold">Beschreibung</div>
            <div className="col-3 fw-bold d-none d-md-block">Erstellt am</div>
          </div>
          {vehicles && vehicles.map(vehicle => <React.Fragment key={vehicle.vehicle_id}>
            <div className="row hover-light p-2 cursor-pointer" onClick={() => setRedirect(`/vehicle/detail/${vehicle.vehicle_id}`)}>
              <div className="col-6 col-md-3 py-1 text-uppercase">{vehicle.vin}</div>
              <div className="col-6 col-6 py-1">{vehicle.description}</div>
              <div className="col-3 py-1 d-none d-md-block">{new Intl.DateTimeFormat('de-DE', {day: 'numeric', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(vehicle.created_at))} Uhr</div>
            </div>
          </React.Fragment>)}
        </div>
      </Wrapper>
    </React.Fragment>
}