import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

import 'bootstrap/js/dist/modal';

export default function EvaluationDetail({match}) {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [vehicle, setVehicle] = useState(undefined);
  const [mileage, setMileage] = useState('');
  const headers = useFetchHeaders();
  const [redirect] = useState(null);

  useEffect(() => {

    if (!vehicle) {
      fetchData();
    }
  });

  const fetchData = async () => {
    const request = await fetch(`${process.env.REACT_APP_API_HOST}/vehicle/${match.params.vehicleId}`, {headers});

    const {status} = await request;

    if (status === 200) {
      const data = await request.json();
      setVehicle(data);
    } else {
      console.log("error", status);
    }
  }

  const handleSubmitEvaluateVehicle = async (event) => {
    event.preventDefault();

    const request = await fetch(`${process.env.REACT_APP_API_HOST}/vehicle/valuate/${match.params.vehicleId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({vin: vehicle.vin, mileage}),
    });

    const {status} = await request;
    
    if (status === 201) {
      document.querySelector('#vehicle-evaluate-modal button[data-bs-dismiss="modal"]').click();
      fetchData();
    } else {
      console.log("error", status);
    }
  }

  const modalVehicleEvaluate = () => <>
    <form onSubmit={handleSubmitEvaluateVehicle} className="modal fade" id="vehicle-evaluate-modal" data-bs-keyboard="false" aria-labelledby="vehicle-evaluate-modal-label" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="p fw-bold">Jetzt Fahrzeug bewerten</div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col">
                  <input type="tel" className="form-control" placeholder="Kilometerstand" value={mileage} onChange={(e) => setMileage(e.target.value)} required />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-link" data-bs-dismiss="modal">Schließen</button>
            <button type="submit" className="btn btn-primary">Jetzt bewerten</button>
          </div>
        </div>
      </div>
    </form>
  </>
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      {modalVehicleEvaluate()}
      <Wrapper title={`${vehicle?.manufacturer} ${vehicle?.description}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <span className="mt-2 me-3 bg-white px-1 rounded h5 fw-light text-primary">
                <span className="fw-bold">FIN</span> {vehicle?.vin}
              </span>
              <span className="mt-2 me-3 bg-white px-1 rounded h5 fw-light text-primary">
                <span className="fw-bold">Erstellt am</span> {vehicle && new Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(vehicle.created_at))}
              </span>
              <span className="mt-2 me-3 bg-white px-1 rounded h5 fw-light text-primary">
                <span className="fw-bold">Bearbeitet am</span> {vehicle && new Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(vehicle.updated_at))}
              </span>
            </div>
            <div className="col-3 text-end">
              <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#vehicle-evaluate-modal">Fahrzeug bewerten</button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Erstzulassung</span>
              {vehicle && new Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(vehicle.date_vehicle_first_registered))}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Kilometerstand</span>
              {vehicle?.mileage_from_odometer}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Model</span>
              {vehicle?.model}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Model</span>
              {vehicle?.sub_model}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Listenneupreis (inkl. Sonderausstattungen)</span>
              {vehicle?.original_price}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className="fw-bold d-block">Listenneupreis (inkl. Sonderausstattungen) Brutto</span>
              {vehicle?.original_price_gross}
            </div>
          </div>
        </div>

        <div className="mt-5 mb-3 ms-4 h2 fw-bold">Fahrzeugbewertungen</div>

        {vehicle?.evaluations && vehicle.evaluations.length === 0 ? 
          <React.Fragment>
            <div className="container-fluid mt-3">
              <div className="row bg-light rounded p-2">
                <div className="col-12 fw-bold">
                  Es sind keine Bewertungen für dieses Fahrzeug hinterlegt.
                </div>
              </div>
            </div>
          </React.Fragment> : 
          <React.Fragment>
            <div className="container-fluid mt-3">
              <div className="row bg-light rounded p-2">
                <div className="col-5 fw-bold">Datum</div>
                <div className="col-2 fw-bold text-end">Kilometerstand</div>
                <div className="col-3 fw-bold text-end">Händlereinkaufswert</div>
                <div className="col-2 fw-bold text-end">Verkaufswert</div>
              </div>
              {vehicle?.evaluations && vehicle.evaluations.length > 0 ? vehicle.evaluations.map(evaluation => <React.Fragment key={evaluation.evaluation_id}>
                <div className="row p-2">
                  <div className="col-5">
                    <span className="d-block">{new Intl.DateTimeFormat('de-DE', {weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}).format(new Date(evaluation.created_at))} Uhr</span>
                  </div>
                  <div className="col-2 text-end">
                    <span className="d-block">{new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(evaluation.mileage)} km</span>
                  </div>
                  <div className="col-3 text-end">
                    <span className="d-block">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(evaluation.purchase_price_gross)}</span>
                  </div>
                  <div className="col-2 text-end">
                    <span className="d-block">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(evaluation.sales_price_gross)}</span>
                  </div>
                </div>
              </React.Fragment>) : null}
            </div>
          </React.Fragment>}
            
        {/* 
            <div className="row mt-3">
              <div className="col-2 fw-normal text-muted">
                Amtl. Kennzeichen<br/>
                Vorgangsname<br/>
                Hersteller<br/>
                Modell<br/>
                Typ<br/>
                Ausstattungslinie<br/>
                <br/>
                Leistung<br/>
                Hubraum<br/>
                Motorart<br/>
                Getriebe<br/>
              </div>
              <div className="col-4 fw-normal">
                ??? ES-CD 17<br/>
                ??? B37805<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.ManufacturerName['_']}<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.BaseModelName['_']}<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.SubModelName['_']}<br/>
                -<br/>
                <br/>
                ??? 184 kW/250 PS<br/>
                ??? 1999 ccm<br/>
                ??? Superbenzin<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.Equipment.SeriesEquipment.EquipmentPosition.find(position => position.DatEquipmentId === '75211').Description}<br/>
              </div>
              <div className="col-2 fw-normal text-muted">
                Erstzulassung / HU<br/>
                Laufleistung<br/>
                Anzahl Halter<br/>
                Farbe<br/>
                DAT €uropa-Code®<br/>
                VIN<br/>
                Aufbau<br/>
                Sitzplätze / Türen<br/>
                Unfallschaden<br/>
                KBA-Schlüssel<br/>
              </div>
              <div className="col-4 fw-normal">
                {vehicle ? new Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(vehicle?.evaluatedVehicleJSON.Vehicle.InitialRegistration.split('+')[0])) : null} / ???<br/>
                {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(vehicle?.evaluatedVehicleJSON.Vehicle.MileageEstimated)} km<br/>
                ??? 1<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.Equipment.Color['_']}<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.DatECode}<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.VehicleIdentNumber}<br/>
                {vehicle?.evaluatedVehicleJSON.Vehicle.Equipment.SeriesEquipment.EquipmentPosition.find(position => position.DatEquipmentId === '10005').Description}<br/>
                ???  5 / 5<br/>
                ???  unfallfrei<br/>
                ???  8566/BFG<br/>
              </div>
            </div> */}




            {/* <div className="row bg-light mt-3 p-2 rounded">
              <div className="col-12 fw-bold">Bewertung</div>
            </div>
            <div className="row mt-3">
              <div className="col-10 text-muted">Listenneupreis nach DAT €uropa-Code® und Marktindex ohne Sonderausstattungen</div>
              <div className="col-2 text-end">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle?.evaluatedVehicleJSON.Valuation.OriginalPrice['_'])}</div>
            </div>
            <div className="row">
              <div className="col-10 text-muted">Listenneupreis für zusätzlich berücksichtigte Sonderausstattungen</div>
              <div className="col-2 text-end">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle?.evaluatedVehicleJSON.Valuation.EquipmentOriginalPrice['_'])}</div>
            </div> */}
            {/* <div className="row">
              <div className="col-10 text-muted">Listenneupreis gesamt</div>
              <div className="col-2 text-end">
                {vehicle ? 
                  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(parseFloat(vehicle?.evaluatedVehicleJSON.Valuation.OriginalPrice['_'])+parseFloat(vehicle?.evaluatedVehicleJSON.Valuation.EquipmentOriginalPrice['_'])) : 
                  0
                }
              </div>
            </div> */}
            {/* <div className="row mt-3">
              <div className="col-10 text-muted fw-bold">Händlerverkaufswert (zuzüglich 19,00 % MwSt. 367,25 EUR)</div>
              <div className="col-2 text-end">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle?.evaluatedVehicleJSON.Valuation.SalesPrice['_'])}</div>
            </div>
            <div className="row mt-3">
              <div className="col-10 text-muted fw-bold">Händlereinkaufswert</div>
              <div className="col-2 text-end">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle?.evaluatedVehicleJSON.Valuation.PurchasePriceGross['_'])}</div>
            </div>
            <div className="row bg-light mt-3 p-2 rounded">
              <div className="col-12 fw-bold">Ausstattungen</div>
            </div>
            <div className="row mt-3 mb-2">
              <div className="col-12 fw-bold">Serienausstattung</div>
            </div> */}
            
            {/* {vehicle?.evaluatedVehicleJSON.Vehicle.Equipment.SeriesEquipment.EquipmentPosition.map(equipment => <React.Fragment key={equipment.DatEquipmentId}>
              <div className="row">
                <div className="col-12 text-muted">
                  <span className="ms-2">{equipment.Description} ({equipment.DatEquipmentId})</span>

                  {equipment.ContainedEquipmentPositions && equipment.ContainedEquipmentPositions.EquipmentPosition.length > 0 ? equipment.ContainedEquipmentPositions.EquipmentPosition.map(containedEquipment => <React.Fragment key={containedEquipment.DatEquipmentId}>
                    <div className="row ms-2">
                      <div className="col-12 text-muted">
                        <span className="ms-2">{containedEquipment.Description} ({containedEquipment.DatEquipmentId})</span>
                      </div>
                    </div>
                  </React.Fragment>) : null}

                  {equipment.ContainedEquipmentPositions && !equipment.ContainedEquipmentPositions.EquipmentPosition.length ? <React.Fragment>
                    <div className="row ms-2">
                      <div className="col-12 text-muted">
                        <span className="ms-2">{equipment.ContainedEquipmentPositions.EquipmentPosition.Description} ({equipment.ContainedEquipmentPositions.EquipmentPosition.DatEquipmentId})</span>
                      </div>
                    </div>
                  </React.Fragment> : null}

                </div>
              </div>
            </React.Fragment>)} */}


          {/* </div>
        ) :
        <div className="container-fluid bg-light py-2 mt-5 border-top">
          <div className="row">
            <div className="col-12">
              <span className="fw-bold d-block">Keine Bewertungen für dieses Fahrzeug</span>
            </div>
          </div>
        </div>} */}










          {/* <div className="row mt-3 mb-2">
            <div className="col-12 fw-bold">Sonderausstattung</div>
          </div>
          <div className="row mt-3 mb-2 ms-2">
            <div className="col-6 fw-bold">Bezeichnung</div>
            <div className="col-2 fw-bold text-end">Neupreis EUR</div>
            <div className="col-2 fw-bold">Abw.</div>
            <div className="col-2 fw-bold text-end">Restwert EUR</div>
          </div>

          {vehicle?.evaluatedVehicleJSON.Vehicle.Equipment.SpecialEquipment.EquipmentPosition.map(equipment => <React.Fragment key={equipment.DatEquipmentId}>
            <div className="row ms-0">
              <div className="col-6 text-muted">
                <span className="ms-2">{equipment.Description} ({equipment.DatEquipmentId})</span>
              </div>
              <div className="col-2 text-muted text-end">
                <span className="ms-2">{equipment.OriginalPrice}</span>
              </div>
            </div>
          </React.Fragment>)} */}

        {/* </div> */}
      </Wrapper>
    </React.Fragment>
}