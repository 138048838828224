import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function CustomerAdd() {  
  const [formCity, setFormCity] = useState('');
  const [formCompanyName, setFormCompanyName] = useState('');
  const [formFirstname, setFormFirstname] = useState('');
  const [formLastname, setFormLastname] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formSalutation, setFormSalutation] = useState('');
  const [formStreet, setFormStreet] = useState('');
  const [formZipcode, setFormZipcode] = useState('');
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const request = await fetch(`${process.env.REACT_APP_API_HOST}/customer`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        city: formCity,
        companyName: formCompanyName,
        firstname: formFirstname,
        lastname: formLastname,
        email: formEmail,
        phone: formPhone,
        salutation: formSalutation,
        street: formStreet,
        zipcode: formZipcode,
      }),
    });

    const {status} = await request;

    if (status === 201) {
      setRedirect('/customers');
    } else {
      console.log("error", status);
    }
  }
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Neuer Kunde">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className=" row mt-3">
              <div className="col-12">
                <input type="text" className="form-control form-control-lg" value={formCompanyName} onChange={(e) => setFormCompanyName(e.target.value)} placeholder="Firma" required />
              </div>
            </div>
            <div className=" row mt-3">
              <div className="col-12">
                <input type="text" className="form-control form-control-lg" value={formStreet} onChange={(e) => setFormStreet(e.target.value)} placeholder="Strasse / Hausnummer" required />
              </div>
            </div>
            <div className=" row mt-3">
              <div className="col-12 col-md-3">
                <input type="text" className="form-control form-control-lg" value={formZipcode} onChange={(e) => setFormZipcode(e.target.value)} placeholder="Postleitzahl" required />
              </div>
              <div className="col-12 col-md-9">
                <input type="text" className="form-control form-control-lg" value={formCity} onChange={(e) => setFormCity(e.target.value)} placeholder="Stadt" required />
              </div>
            </div>
            <div className="row  mt-5">
              <div className="col-12">Ansprechpartner</div>
            </div>
            <div className=" row mt-3">
              <div className="col-12 col-md-2">
                <select name="salutation" className="form-control form-control-lg" onChange={(e) => setFormSalutation(e.target.value)}>
                  <option value="0">Auswählen</option>
                  <option value="male" selected={formSalutation === 'male' ? 'selected' : null}>Herr</option>
                  <option value="female" selected={formSalutation === 'female' ? 'selected' : null}>Frau</option>
                  <option value="diverse" selected={formSalutation === 'diverse' ? 'selected' : null}>Divers</option>
                  <option value="notspecified" selected={formSalutation === 'notspecified' ? 'selected' : null}>Keine Angabe</option>
                </select>
              </div>
              <div className="col-12 col-md-5">
                <input type="text" className="form-control form-control-lg" value={formFirstname} onChange={(e) => setFormFirstname(e.target.value)} placeholder="Vorname" required />
              </div>
              <div className="col-12 col-md-5">
                <input type="text" className="form-control form-control-lg" value={formLastname} onChange={(e) => setFormLastname(e.target.value)} placeholder="Nachname" required />
              </div>
            </div>
            <div className=" row mt-3">
              <div className="col-12">
                <input type="text" className="form-control form-control-lg" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} placeholder="E-Mail" required />
              </div>
            </div>
            <div className=" row mt-3">
              <div className="col-12">
                <input type="tel" className="form-control form-control-lg" value={formPhone} onChange={(e) => setFormPhone(e.target.value)} placeholder="Telefon" required />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-end">
                <button type="submit" className="mt-4 btn btn-secondary btn-lg w-md-0 px-5 border-white">
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Speichern</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
    </React.Fragment>
}