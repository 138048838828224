import React from 'react';
import Navigation from './Navigation';

const Wrapper = ({children, title, subTitle}) => <React.Fragment>
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 col-lg-3 col-xl-2 d-none d-lg-block p-0"><Navigation /></div>
      <div className="col-12 col-lg-9 col-xl-10 px-lg-5 py-4">
        <div className="mt-5">
          <h1 className="pb-3 mx-4 mb-3 fw-bold">
            {title}
            {subTitle ? <div className="">{subTitle.map(item => <span key={item} className="mt-2 me-3 bg-white px-1 rounded h5 fw-light text-primary">{item}</span>)}</div> : null}
          </h1>
          {children}
        </div>
      </div>
    </div>
  </div>
</React.Fragment>

export default Wrapper;