import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import useFetchHeaders from '../helper/fetchHeaders';
import Wrapper from '../components/Wrapper';

const UserDetail = ({match}) => {
  import('../helper/checkSession').then(check => check.default(true));
  
  const headers = useFetchHeaders();
  const [redirect] = useState(null);
  const [user, setUser] = useState(false);

  useEffect(() => {
    if (!user) {
      fetchUser(match.params.userId);
    }
  });

  const fetchUser = async (userId) => {
    const request = await fetch(`${process.env.REACT_APP_API_HOST}/user/${userId}`, {headers});
    const {status} = await request;
    
    if (status === 200) {
      const response = await request.json();
      
      setUser(response);
    }
  }
  
  return redirect ? 
    <Redirect to={redirect} /> : user ? <React.Fragment>
      <Wrapper title={`${user.firstname} ${user.lastname}`} navigationItems={[{name: 'Löschen', to: `/user/delete/${user.user_id}`}]}>

      <div className="container-fluid mb-5" style={{marginTop: '-70px'}}>
          <div className="row">
            <div className="col-12 text-end">
              <div className="btn-group me-2" role="group">
                <Link to={`/user/delete/${user.user_id}`} type="button" className="btn btn-dark">Löschen</Link>
              </div>
            </div>
          </div>
        </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row mb-2 bg-white p-2 rounded">
              <div className="col fw-bold">ID</div>
              <div className="col fw-bold">{user.user_id}</div>
            </div>
            <div className="row mb-2 bg-white p-2 rounded">
              <div className="col fw-bold">E-Mail</div>
              <div className="col fw-bold">{user.email}</div>
            </div>
            <div className="row mb-2 bg-white p-2 rounded">
              <div className="col fw-bold">Status</div>
              <div className="col fw-bold">{user.status}</div>
            </div>
            <div className="row mb-2 bg-white p-2 rounded">
              <div className="col fw-bold">Bearbeitet am</div>
              <div className="col fw-bold">{user.created_at === user.updated_at ? '-' : `${new Date(user.updated_at).toLocaleDateString(navigator.language, {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})} Uhr`}</div>
            </div>
            <div className="row mb-2 bg-white p-2 rounded">
              <div className="col fw-bold">Erstellt am</div>
              <div className="col fw-bold">{new Date(user.created_at).toLocaleDateString(navigator.language, {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})} Uhr</div>
            </div>
          </div>
        </div>
      </div>

      </Wrapper>
    </React.Fragment> : <>Loading</>;
}

export default UserDetail;