import React, {useContext} from 'react';

import StoreContext from '../../storeContext';

const Success200 = () => {
  const {state} = useContext(StoreContext);

  return state.showFormSuccess && <>
    <div className="container-fluid my-5 px-5">
      <div className="container bg-success rounded shadow text-white p-3 p-md-4">
        <div className="row">
          <div className="col-12 text-center">
            <i className="fas fa-rocket fa-4x" />
            <h3 className="fw-bolder mt-3">Fahrzeugbewertung erfolgreich</h3>
            <p>
              Vielen Dank für die Nutzung unserer Fahrzeugbewertung. 
              Ihre Fahrzeugbewertung haben wir Ihnen soeben per E-Mail an {state.formMail} zugesandt.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Success200;