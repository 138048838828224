import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

const SessionExpired = () => {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    handleRedirect();
    return () => setRedirect(null);
  }, []);

  const handleRedirect = async () => {
    setTimeout(() => {
      setRedirect('/login');
    }, 3000);
  }

  return redirect ? 
    <Redirect to={redirect} /> : 
    <div className="container-fluid bg-primary text-white">
      <div className="row vh-100 align-items-center">
        <div className="col-12 text-center">
          <i className="fas fa-sad-tear fa-10x"></i>
          <h1 className="mt-3 fw-bold text-uppercase">Deine Session ist abgelaufen!</h1>
          <p>Du wirst automatisch weitergeleitet. <br/> Passiert nichts? <a href="/login" className="text-white font-weight-bold">Dann klick einfach hier.</a></p>
        </div>
      </div>
    </div>;
}

export default SessionExpired;