import React from 'react';
import {Link} from 'react-router-dom';

const Navigation = () => <React.Fragment>
  <div className="container-fluid border-end vh-100 bg-light">
    <div className="row">
      <div className="col-12 text-center">
        <i className="fas fa-user-tie fa-6x text-dark mt-3 cursor-pointer" />
        <h2 className="text-dark fw-light mt-3 text-center text-uppercase">kraft<span className="fw-bold">karre</span></h2>
      </div>
    </div>
    <div className="row">
      <div className="col-12 mt-5 text-start">
        <Link to="/dashboard" className="text-dark d-block h4 fw-normal h4 text-decoration-none"><i className="fas fa-home fa-fw me-2" />Dashboard</Link>
        <Link to="/customers" className="text-dark d-block h4 fw-normal h4 text-decoration-none mt-3"><i className="fas fa-users fa-fw me-2" />Kunden</Link>
        <Link to="/vehicles" className="text-dark d-block h4 fw-normal h4 text-decoration-none mt-3"><i className="fas fa-car fa-fw me-2" />Fahrzeuge</Link>
        <Link to="/preparations" className="text-dark d-block h4 fw-normal h4 text-decoration-none mt-3"><i className="fas fa-hand-sparkles fa-fw me-2" />Aufbereitungen</Link>

        {/* <div className="mt-5">
          <div className="fw-bold">Schnittstellen</div>
          <Link to="/evaluations" className="text-dark d-block h4 fw-normal h4 text-decoration-none mt-3"><i className="fas fa-sync fa-fw me-2" />mobile.de</Link>
        </div> */}
        <div className="mt-5 border-top">
          <Link to="/users" className="text-dark d-block h4 fw-normal h4 text-decoration-none mt-3"><i className="fas fa-user-shield fa-fw me-2" />Benutzer</Link>
          <Link to="/logout" className="text-dark d-block h4 fw-normal h4 text-decoration-none mt-3"><i className="fas fa-sign-out-alt fa-fw me-2" />Abmelden</Link>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <div className="mt-5 small">
          Ein Projekt der stigits GmbH<br/>
          Version: {process.env.REACT_APP_VERSION}
        </div>
      </div>
    </div>
  </div>
</React.Fragment>

export default Navigation;