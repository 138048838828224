import React, {useContext, useEffect} from 'react';

import StoreContext from '../../storeContext';

const IdentifyVehicleByDropDown = () => {
  const {dispatch, state} = useContext(StoreContext);

  useEffect(() => {
    const fetchIdentify = async () => {
      const url = new URL(`${process.env.REACT_APP_API_HOST}/identify`);
      
      if (state.selectedManufacturer) url.searchParams.append('manufacturerId', state.selectedManufacturer);
      if (state.selectedBaseModel) url.searchParams.append('baseModelId', state.selectedBaseModel);
      if (state.selectedSubModel) url.searchParams.append('subModelId', state.selectedSubModel);
      if (state.selectedGearingOption) url.searchParams.append('gearingId', state.selectedGearingOption);
      if (state.selectedCarBodyOption) url.searchParams.append('carBodyId', state.selectedCarBodyOption);
      if (state.selectedEngineOption) url.searchParams.append('engineId', state.selectedEngineOption);

      const request = await fetch(url);

      const {status} = await request;

      if (status === 200) {
        const data = await request.json();

        dispatch({type: 'UPDATE_STORE', payload: {
          identify: data,
        }});
      } else {
        console.log("error", status);
      }
    }

    fetchIdentify();

    if (state.selectedManufacturer && state.selectedBaseModel && state.selectedSubModel && state.selectedGearingOption && state.selectedCarBodyOption && state.selectedEngineOption && !state.identify.datECode) {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormErrorIdentifyVehicle: true,
      }});
    } else {
      dispatch({type: 'UPDATE_STORE', payload: {
        showFormErrorIdentifyVehicle: false,
      }});
    }

  }, [
    dispatch,
    state.identify.datECode, 
    state.selectedManufacturer, 
    state.selectedBaseModel, 
    state.selectedSubModel, 
    state.selectedCarBodyOption, 
    state.selectedEngineOption, 
    state.selectedGearingOption
  ]);
  
  const handleSubmitIdentify = async (event) => {
    event.preventDefault();

    dispatch({type: 'UPDATE_STORE', payload: {
      showFormIdentifyVehicleByDropDown: false,
      showFormContact: true,
    }});
  }

  return state.showFormIdentifyVehicleByDropDown ? <>
    <form onSubmit={handleSubmitIdentify}>
      <div className="container-fluid my-5 px-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-end">
              <div className="text-secondary cursor-pointer" onClick={(e) => dispatch({type: 'UPDATE_STORE', payload: {showFormIdentifyVehicleByDropDown: false, showFormIdentifyVehicleByVin: true}})}>&raquo; Ich kenne meine <span className="fw-bold">Fahrzeug-Identifizierungsnummer (FIN)</span></div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span>Marke</span>
              <select className="form-select form-select-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {selectedManufacturer: e.target.value}})} required>
                <option value="">Bitte auswählen</option>
                <optgroup label="Top Marken">
                  {state.identify.manufacturers && state.identify.manufacturers.filter(manufacturer => ['Audi', 'BMW', 'Ford', 'Mercedes-Benz', 'Opel', 'Volkswagen'].includes(manufacturer.value)).map(manufacturer => <option key={manufacturer.value} value={manufacturer.key}>{manufacturer.value}</option>)}
                </optgroup>
                <optgroup label="Andere">
                  {state.identify.manufacturers && state.identify.manufacturers.filter(manufacturer => !['Audi', 'BMW', 'Ford', 'Mercedes-Benz', 'Opel', 'Volkswagen'].includes(manufacturer.value)).map(manufacturer => <option key={manufacturer.value} value={manufacturer.key}>{manufacturer.value}</option>)}
                </optgroup>
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <span>Modell</span>
              <select className="form-select form-select-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {selectedBaseModel: e.target.value}})} disabled={!state.selectedManufacturer ? 'disabled' : ''} required>
                <option value="">Bitte auswählen</option>
                {state.identify.baseModels && state.identify.baseModels.map(baseModel => <option key={baseModel.key} value={baseModel.key}>{baseModel.value}</option>)}
              </select>
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <span>Variante</span>
              <select className="form-select form-select-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {selectedSubModel: e.target.value}})} disabled={!state.selectedBaseModel ? 'disabled' : ''} required>
                <option value="">Bitte auswählen</option>
                {state.identify.subModels && state.selectedBaseModel && state.identify.subModels.map(subModel => <option key={subModel.key} value={subModel.key}>{subModel.value}</option>)}
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-4">
              <span>Karosserie</span>
              <select className="form-select form-select-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {selectedCarBodyOption: e.target.value}})} disabled={!state.selectedSubModel ? 'disabled' : ''} required>
                <option value="">Bitte auswählen</option>
                {state.identify.carBodyOptions && state.selectedBaseModel && state.identify.carBodyOptions.map(carBody => <option key={carBody.key} value={carBody.key}>{carBody.value}</option>)}
              </select>
            </div>
            <div className="col-12 col-md-4 mt-3 mt-md-0">
              <span>Leistung</span>
              <select className="form-select form-select-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {selectedEngineOption: e.target.value}})} disabled={!state.selectedCarBodyOption ? 'disabled' : ''} required>
                <option value="">Bitte auswählen</option>
                {state.identify.engineOptions && state.selectedBaseModel && state.identify.engineOptions.map(engine => <option key={engine.key} value={engine.key}>{engine.value}</option>)}
              </select>
            </div>
            <div className="col-12 col-md-4 mt-3 mt-md-0">
              <span>Getriebe</span>
              <select className="form-select form-select-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {selectedGearingOption: e.target.value}})} disabled={!state.selectedEngineOption ? 'disabled' : ''} required>
                <option value="">Bitte auswählen</option>
                {state.identify.gearingOptions && state.selectedBaseModel && state.identify.gearingOptions.map(gearing => <option key={gearing.key} value={gearing.key}>{gearing.value}</option>)}
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <span>Erstzulassung</span>
              <input type="date" className="form-control form-control-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formRegistrationDate: e.target.value}})} disabled={!state.selectedGearingOption ? 'disabled' : ''} max={new Date().toISOString().split('T').shift()} required />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <span>Kilometerstand (km)</span>
              <input type="tel" className="form-control form-control-lg" onChange={(e) => dispatch({type: 'UPDATE_STORE', payload: {formMileage: e.target.value}})} disabled={!state.formRegistrationDate ? 'disabled' : ''} required />
            </div>
          </div>
          {state.showFormErrorIdentifyVehicle ?
          <div className="row mt-5">
            <div className="col-12 text-center border bg-light">
              <p className="fw-bolder mt-3">Wir können das Auto leider nicht identifizieren.</p>
              <p className="fw-bold">
                Gerne können wir das Fahrzeug auch vor Ort kostenlos bewerten <a href="mailto:info@pskraftwagen.de?Subject=Manuelle Fahrzeugbewertung">info@pskraftwagen.de</a>
              </p>
            </div>
          </div> : null}
          <div className="row mt-3">
            <div className="col-12 text-end">
              <button type="submit" className="mt-4 btn btn-secondary btn-lg w-md-0 px-5 border-white" disabled={!state.showFormErrorIdentifyVehicle && state.selectedManufacturer && state.selectedBaseModel && state.selectedCarBodyOption && state.selectedEngineOption && state.selectedGearingOption && state.selectedSubModel && state.formMileage && state.identify.datECode ? '' : 'disabled'}>
                <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Weiter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </> : null
}

export default IdentifyVehicleByDropDown;