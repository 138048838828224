import React from 'react';

const AccessTokenInvalid = () => {
  
  return <>
    <div className="container-fluid my-5 px-5">
      <div className="container p-4">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <i className="fas fa-sad-tear fa-4x" />
            <h3 className="fw-bolder mt-3">Der Access Token ist ungülitig</h3>
            <p className="mt-4">
              Wenn Sie unsere Web Komponenten verwenden, stellen sie bitte sicher, dass Sie einen gültigen Access Token verwenden.
              Diesen finden Sie in Ihrem Kundenbereich auf <a className="fw-bold" href="https://www.kraftkarre.de">kraftkarre.de</a>.
            </p>
            <p>
              Gerne dürfen Sie uns bei Fragen kontaktieren, wir helfen! <a href="mailto:support@kraftkarre.de?Subject=AccessToken ungültig" className="fw-bold">support@kraftkarre.de</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default AccessTokenInvalid;