import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [formError, setFormError] = useState(false);
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(null);

  const handleSubmit = async(event) => {
    event.preventDefault();

    try {
      const loginRequest = await fetch(`${process.env.REACT_APP_API_HOST}/user`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({firstname, lastname, email, password})
      });

      const {status} = await loginRequest;

      if (status === 200) {
        setRedirect('/registerSuccessful');
      } else {
        setFormError(true);
      }
    } catch(e) {
      setFormError(true);
    }
  }

  const formErrorSection = () => formError ? <React.Fragment>
    <div className="container my-3">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 bg-light rounded p-3">
          <p className="m-0 font-weight-bold text-danger">Hups!</p>
          <p className="m-0">Da ist etwas schief gelaufen.</p>
        </div>
      </div>
    </div>
  </React.Fragment> : null;
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
    <header className="container">
      <div className="row">
        <div className="col-12 text-center">
          <img src="/images/logo-kraftkarre.png" alt="Kraftkarre" className="mt-5 cursor-pointer" />
        </div>
      </div>
    </header>
    {formErrorSection()}
    <form className="container" onSubmit={handleSubmit}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-4">
          <h2 className="text-primary font-weight-light mt-3 text-center">Registrieren</h2>
          <input type="text" className="form-control mt-4" placeholder="Vorname" value={firstname} onChange={(e) => setFirstname(e.target.value)} required />
          <input type="text" className="form-control mt-4" placeholder="Nachname" value={lastname} onChange={(e) => setLastname(e.target.value)} required />
          <input type="text" className="form-control mt-4" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <input type="password" className="form-control mt-3" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 text-right">
          <button className="btn btn-primary w-100 mt-4" type="submit">Registrieren</button>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-md-6 col-lg-4 border-top">
          <Link to="/login" className="mt-2 d-block">Login</Link>
          <a href="/" className="mt-2 d-block">Passwort vergessen?</a>
        </div>
      </div>
    </form>
    </React.Fragment>
}

export default Login;