import React, {useContext} from 'react';

import StoreContext from '../../storeContext';

const Error500 = () => {
  const {state} = useContext(StoreContext);
  
  return state.showFormTechnicalError && <>
    <div className="container-fluid my-5 px-5">
      <div className="container bg-danger rounded shadow text-white p-4">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9 text-center">
            <i className="fas fa-sad-tear fa-4x" />
            <h3 className="fw-bolder mt-3">Technischer Fehler</h3>
            <p>
              Hups, ein Fehler 500 ist aufgetreten.
              Das hätte nicht passieren dürfen. Bitte versuchen Sie es noch einmal oder kontaktieren uns telefonisch.
            </p>
            <p>
              Gerne dürfen Sie uns über diesen Fehler informieren <a href="mailto:support@pskraftwagen.de?Subject=Seite Aufbereitung - Fehler 500" className="text-white fw-bold">support@pskraftwagen.de</a>
            </p>
            <a href="/ankauf" className="btn btn-light fw-bold mt-4">Seite neu laden</a>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Error500;