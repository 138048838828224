import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function Customers() {  
  const [customers, setCustomers] = useState(undefined);
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(`${process.env.REACT_APP_API_HOST}/customers`, {headers});
  
      const {status} = await request;
  
      if (status === 200) {
        const data = await request.json();
        setCustomers(data);
      } else {
        console.log("error", status);
      }
    }
    if (!customers) {
      fetchData();
    }
  });
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Kunden">
        <div className="container-fluid mt-n5">
          <div className="row">
            <div className="col-12 text-end mt-n3">
              <Link to="/customer/add" className="btn btn-primary">Neuer Kunde</Link>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row bg-light p-2 rounded">
            <div className="col-6 col-md-3 fw-bold">Firma</div>
            <div className="col-6 fw-bold">Ansprechpartner</div>
            <div className="col-3 fw-bold d-none d-md-block">Erstellt am</div>
          </div>
          {customers && customers.map(customer => <React.Fragment key={customer.customer_id}>
            <div className="row hover-light p-2 cursor-pointer" onClick={() => setRedirect(`/customer/detail/${customer.customer_id}`)}>
              <div className="col-6 col-md-3 py-1">{customer.company_name}</div>
              <div className="col-6 col-6 py-1">{customer.firstname} {customer.lastname}</div>
              <div className="col-3 py-1 d-none d-md-block">{new Intl.DateTimeFormat('de-DE', {day: 'numeric', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(customer.created_at))} Uhr</div>
            </div>
          </React.Fragment>)}
        </div>
      </Wrapper>
    </React.Fragment>
}