import React, {useContext} from 'react';

import StoreContext from '../../storeContext';

const VerificationFailed = () => {
  const {state} = useContext(StoreContext);

  return state.showFormErrorVerificationFailed && <>
    <div className="container-fluid my-5 px-md-5">
      <div className="container bg-danger rounded shadow text-white p-4">
        <div className="row">
          <div className="col-12 text-center">
            <i className="fas fa-car-crash fa-4x" />
            <h3 className="fw-bolder mt-3">Verifizierung fehlgeschlagen</h3>
            <p>
              Hups, es scheint so, als ob Sie einen falschen Bestätigungscode eingegeben haben.
              Bitte gleichen Sie den Bestätigungscode nochmal mit dem in der E-Mail ab und versuchen es dann erneut. 
            </p>
            <p className="fw-bold">
              Gerne können wir das Fahrzeug auch vor Ort kostenlos bewerten <a href="mailto:info@pskraftwagen.de?Subject=Manuelle Fahrzeugbewertung" className="text-white">info@pskraftwagen.de</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default VerificationFailed;