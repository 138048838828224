import React, {useReducer} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import logger from 'use-reducer-logger';

import Customers from './pages/Customers';
import CustomerAdd from './pages/CustomerAdd';
import CustomerDetail from './pages/CustomerDetail';
import Dashboard from './pages/Dashboard';
import Evaluations from './pages/Evaluations';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Preparations from './pages/Preparations';
import PreparationDetail from './pages/PreparationDetail';
import Register from './pages/Register';
import RegisterSuccessful from './pages/RegisterSuccessful';
import SessionExpired from './pages/SessionExpired';
import StoreContext, { initialState, reducer } from './storeContext';
import Users from './pages/Users';
import UserAdd from './pages/UserAdd';
import UserDelete from './pages/UserDelete';
import UserDetail from './pages/UserDetail';
import Vehicles from './pages/Vehicles';
import VehicleAdd from './pages/VehicleAdd';
import VehicleDetail from './pages/VehicleDetail';
import WidgetFahrzeugbewertung from './pages/WidgetFahrzeugbewertung';

const Routes = () => {
  const [state, dispatch] = useReducer(process.env.NODE_ENV === 'development' ? logger(reducer) : reducer, initialState);
  
  return (
    <StoreContext.Provider value={{state, dispatch}}>
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/customer/add" component={CustomerAdd} />
        <Route path="/customer/detail/:customerId" component={CustomerDetail} />
        <Route path="/customers" component={Customers} />
        <Route path="/vehicle/add" component={VehicleAdd} />
        <Route path="/vehicle/detail/:vehicleId" component={VehicleDetail} />
        <Route path="/vehicles" component={Vehicles} />
        <Route path="/user/add" component={UserAdd} />
        <Route path="/user/delete/:userId" component={UserDelete} />
        <Route path="/user/:userId" component={UserDetail} />
        <Route path="/users" component={Users} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/evaluations" component={Evaluations} />
        <Route path="/preparation/detail/:preparationId" component={PreparationDetail} />
        <Route path="/preparations" component={Preparations} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/register" component={Register} />
        <Route path="/registerSuccessful" component={RegisterSuccessful} />
        <Route path="/sessionExpired" component={SessionExpired} />
        <Route path="/widget/fahrzeugbewertung" component={WidgetFahrzeugbewertung} />
        <Route component={Login} />
      </Switch>
    </Router>
    </StoreContext.Provider>
  )
}
export default Routes;