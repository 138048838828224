import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import useFetchHeaders from '../helper/fetchHeaders';

const Logout = () => {
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {

    const handleLogout = async () => {
      const userLogoutData = await fetch(`${process.env.REACT_APP_API_HOST}/user/logout`, {headers});
      const userLogoutResponse = await userLogoutData;

      sessionStorage.removeItem('sid');
  
      if (userLogoutResponse.status === 200 || userLogoutResponse.status === 404) {
        setTimeout(() => {
          setRedirect('/login');
        }, 3000);
      }
    }

    handleLogout();

    return () => setRedirect(null);
  });

  return redirect ? 
    <Redirect to={redirect} /> : 
    <div className="container-fluid bg-light vh-100">
      <div className="container-fluid">
        <div className="row vh-100 align-items-center">
          <div className="col-12 text-center">
            <i className="fas fa-hand-peace fa-10x text-primary"></i>
            <h1 className="fw-bold text-uppercase">Ciaoooo</h1>
            <p>Du wirst automatisch weitergeleitet. <br/> Passiert nichts? <a href="/login" className="fw-bold">Dann klick einfach hier.</a></p>
          </div>
        </div>
      </div>
    </div>;
}

export default Logout;