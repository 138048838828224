import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';

import useFetchHeaders from '../helper/fetchHeaders';
import Wrapper from '../components/Wrapper';

const UserAdd = () => {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [formError, setFormError] = useState(false);
  const headers = useFetchHeaders();
  const [lastname, setLastname] = useState('');
  const [redirect, setRedirect] = useState(null);
  const [sendMail, setSendMail] = useState(null);
  const [status, setStatus] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({firstname, lastname, email, sendMail, status})
    });

    if (userResponse.status === 201) {
      setRedirect('/users');
    } else {
      setFormError(true);
    }
  }
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title={!firstname && !lastname ? 'Neuer Benutzer' : `${firstname} ${lastname}`} subTitle={[
        `Wird erstellt am ${new Date().toLocaleDateString(navigator.language, {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})} Uhr`,
      ]}>
      {formError ? 
        <div className="container mb-4">
          <div className="row">
            <div className="col-12 bg-white border p-3 text-center">
              <i className="fas fa-sad-tear text-danger fa-5x d-block" />
              <b className="text-start d-block">Es gab einen Fehler</b>
              <p className="text-start">Wir konnten {firstname} {lastname} nicht hinzufügen.</p>
              <ul className="text-start">
                <li>Die E-Mail Adresse existiert bereits</li>
                <li>Ein technischer Fehler</li>
              </ul>
            </div>
          </div>
        </div>
      : null}
      <form className="container" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="row mb-3 bg-white p-3 rounded">
              <div className="col-12 fw-bold mb-2">Vor- und Nachname</div>
              <div className="col-12 col-lg-6"><input type="text" className="form-control" placeholder="Vorname" value={firstname} onChange={e => setFirstname(e.target.value)} required autoFocus /></div>
              <div className="col-12 col-lg-6 mt-2 mt-lg-0"><input type="text" className="form-control" placeholder="Nachname" value={lastname} onChange={e => setLastname(e.target.value)} required /></div>
            </div>
            <div className="row mb-3 bg-white p-3 rounded">
              <div className="col-12 fw-bold mb-2">E-Mail Adresse</div>
              <div className="col-12"><input type="email" className="form-control" placeholder="E-Mail" value={email} onChange={e => setEmail(e.target.value)} required /></div>
            </div>
            <div className="row mb-3 bg-white p-3 rounded">
              <div className="col-12 fw-bold mb-2">Status</div>
              <div className="col-12">
                <select name="status" className="form-control" onChange={(e) => setStatus(e.target.value)}>
                  <option value="0">Auswählen</option>
                  <option value="manager" selected={status === 'manager' ? 'selected' : null}>Manager</option>
                  <option value="admin" selected={status === 'admin' ? 'selected' : null}>Administrator</option>
                </select>
              </div>
            </div>
            <div className="row mb-3 bg-white p-3 rounded">
              <div className="col-12 fw-bold mb-2">Sende E-Mail mit Zugangsdaten</div>
              <div className="col-12">
                <select name="sendEmail" className="form-control" onChange={(e) => setSendMail(e.target.value)}>
                  <option value="0">Auswählen</option>
                  <option value="true" selected={sendMail === 'true' ? 'selected' : null}>Ja</option>
                  <option value="false" selected={sendMail === 'false' ? 'selected' : null}>Nein</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-end">
            <button type="button" className="btn btn-link" onClick={() => setRedirect('/users')}>Abbrechen</button>
            {firstname && lastname && email ? <button type="submit" className="btn btn-primary">{firstname ? `${firstname} ${lastname}` : 'Benutzer'} erstellen</button> : null}
          </div>
        </div>
      </form>
      </Wrapper>
    </React.Fragment>;
}

export default UserAdd;