import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import useFetchHeaders from '../helper/fetchHeaders';

export default function VehicleAdd() {
  import('../helper/checkSession').then(check => check.default(true));
  
  const [formVin, setFormVin] = useState('');
  const [formRegistrationDate, setFormRegistrationDate] = useState('');
  const [formMileage, setFormMileage] = useState('');
  const headers = useFetchHeaders();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    
  });

  const handleSubmitIdentify = async (event) => {
    event.preventDefault();

    const request = await fetch(`${process.env.REACT_APP_API_HOST}/vehicle`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({vin: formVin, mileage: formMileage, registrationDate: formRegistrationDate}),
    });

    const {status} = await request;

    if (status === 201) {
      // const data = await request.json();
      setRedirect('/vehicles');
    } else {
      console.log("error", status);
    }
  }
  
  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment>
      <Wrapper title="Neues Fahrzeug">
        <form onSubmit={handleSubmitIdentify}>
          <div className="container">
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <span>Fahrzeug-Identifizierungsnummer (FIN)</span>
                <input type="text" className="form-control form-control-lg" onChange={(e) => setFormVin(e.target.value)} value={formVin} maxLength="17" pattern="[A-Za-z0-9]{17}" placeholder="17-stellige Fahrzeug-Identifizierungsnummer" required />
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12 col-md-6">
                <span>Erstzulassung</span>
                <input type="date" className="form-control form-control-lg" value={formRegistrationDate} onChange={(e) => setFormRegistrationDate(e.target.value)} disabled={!formVin ? 'disabled' : ''} max={new Date().toISOString().split('T').shift()} required />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <span>Kilometerstand (km)</span>
                <input type="tel" className="form-control form-control-lg" value={formMileage} onChange={(e) => setFormMileage(e.target.value)} disabled={!formMileage && !formVin ? 'disabled' : ''} required />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-end">
                <button type="submit" className="mt-4 btn btn-secondary btn-lg w-md-0 px-5 border-white" disabled={formRegistrationDate && formMileage ? '' : 'disabled'}>
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Identifizieren und speichern</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
    </React.Fragment>
}